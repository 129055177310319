<template>
  <div>
    <MappingSelect
      v-if="editGroup === null"
      :mappings="availableMappings"
      :target="target"
      @select-mapping="onSelectMapping"
      @highlight-mesh="onHighlightMesh"
      @edit-group="onEditGroup"
      @new-group="onNewGroup"
    />
    <!-- <MappingGroupEdit
      v-else
      :mappings="availableMappings"
      :group="editGroup"
      @close-group="onCloseGroup"
    /> -->
  </div>
</template>
<script>
const MappingSelect = () => import('./mappings/MappingSelect')
const MappingGroupEdit = () => import('./mappings/MappingGroupEdit')

export default {
  name: 'ModuleMappingSelect',

  components: {
    MappingSelect,
    MappingGroupEdit
  },

  props: ['mod', 'timeline'],

  data() {
    return {
      editGroup: null,
      val: null
    }
  },

  computed: {
    availableMappings() {
      return this.timeline.helper.availableMappings
    },

    target() {
      return this.mod.target
    },

    hasValidTarget() {
      return this.target.length > 0
    }
  },

  methods: {
    onSelectMapping(value) {
      this.val = value
      this.timeline.editModuleTargets(this.mod, value)
    },

    onHighlightMesh(meshName) {
        this.timeline._gui.highlightObjectByName(meshName);
    },

    onSelectMapping(value) {
      this.val = value
      this.timeline.editModuleTargets(this.mod, value)
    },


    onCloseGroup() {
      this.editGroup = null
    },

    onEditGroup(group) {
      console.log('edit group')
      this.editGroup = group
    },

    onNewGroup() {
      // Create a new group..
      this.editGroup = { id: 'new' }
    }
  }
}
</script>
