<template>
  <div class="w-full text-gray-200 bg-gray-700">
    <div
      class="flex items-center justify-between pt-0.5 text-xs bg-gray-700 bg-gradient-to-br from-purple-500 via-purple-400 to-magenta-500 tabs sub-tabs"
    >
      <div class="font-bold rounded-t-sm tab">
        <fa-icon icon="shapes" class="mr-1 text-white fa-fw" />
        <span class="text-white">{{ mod.name }}</span>
        <span class="inline-flex">
          <VDropdown placement="bottom" class="flex w-full truncate" auto-hide>
            <div class="text-white cursor-pointer hover:text-gray-100">
              <fa-icon icon="ellipsis" size="sm" class="ml-1 mr-1 fa-fw" />
            </div>

            <template #popper>
              <ul class="p-1 text-xs leading-loose text-white">
                <li
                  v-close-popover
                  class="flex items-center px-2 py-1 text-xs border border-transparent rounded-md cursor-default group text-neutral-100 hover:bg-neutral-600 hover-neutral-600 hover:border-neutral-400"
                  @click.prevent="onDelete"
                >
                  <fa-icon icon="trash-can" class="mr-1 text-red-500 fa-fw" />
                  Delete module
                </li>
              </ul>
            </template>
          </VDropdown>
        </span>
      </div>

      <div
        class="px-2 text-gray-800 cursor-pointer hover:underline hover:text-white"
        @click.prevent="$emit('close')"
      >
        <fa-icon icon="xmark" />
      </div>
    </div>

    <div class="flex flex-col w-full">
      <div class="overflow-y-scroll module-body text-2xs">
        <AssetBlock :type="type" :mod="mod" :timeline="timeline" />
        <div class="mt-4">
          <SettingsBlock
            :type="type"
            :mod="mod"
            :timeline="timeline"
            @close="$emit('close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlockHeader from '@components/editor/scene/blocks/BlockHeader'
import ViewerContainer from '@components/viewer/ViewerContainer'
import OverviewContainer from '@components/viewer/OverviewContainer'
import AssetBlock from './blocks/AssetBlock'
import SettingsBlock from './blocks/SettingsBlock'

const ModuleDeleteModal = () => import('@modals/ModuleDelete')

export default {
  name: 'ModuleOverview',

  components: {
    BlockHeader,
    ViewerContainer,
    OverviewContainer,
    AssetBlock,
    SettingsBlock
  },

  props: ['type', 'mod', 'timeline'],

  methods: {
    onDelete() {
      this.$modal.show(
        ModuleDeleteModal,
        {
          mod: this.mod,
          successCallback: () => {
            this.$emit('close')
          }
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    }
  }
}
</script>
