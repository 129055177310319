<template>
  <div class="flex flex-wrap bg-gray-700">
    <div class="w-full">
      <BlockGroup :label="'Mapping'" :closed="false">
        <template v-slot:header> Target </template>

        <div class="ml-2 bg-gray-800">
          <BlockGroup :label="'Asset'" :closed="false">
            <template v-slot:header>
              Asset
              <span v-if="!hasAsset" class="ml-1 text-red-500"
                ><fa-icon icon="triangle-exclamation" class="fa-fw" /> None
                Selected</span
              >
            </template>

            <template v-if="hasAsset">
              <div class="flex items-center justify-between w-full p-4">
                <AssetItemThumb :asset="asset" />
                <a
                  href="#"
                  class="inline-flex items-center px-2 py-1 text-xs font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click.prevent="onSelect"
                >
                  Replace
                </a>
              </div>
            </template>
            <template v-else>
              <div class="flex items-center px-4 py-2">
                <div class="mr-auto">
                  <h3 class="text-xs leading-6">No asset selected</h3>
                </div>
                <a
                  href="#"
                  class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click.prevent="onSelect"
                >
                  Select
                </a>
              </div>
            </template>
          </BlockGroup>

          <BlockGroup :label="'Mapping'" :closed="false">
            <template v-slot:header>
              Mapping
              <span v-if="!hasMapping" class="ml-1 text-red-500"
                ><fa-icon icon="triangle-exclamation" class="fa-fw" /> None
                Selected</span
              >
            </template>

            <ModuleMappingSelect :mod="mod" :timeline="timeline" />
          </BlockGroup>

          <BlockGroup :label="'Options'" :level="1" :closed="false">
            <ModuleAdvancedOptions :mod="mod" :timeline="timeline" />
          </BlockGroup>
        </div>
      </BlockGroup>
    </div>
  </div>
</template>
<script>
import mixin from './ModuleMixin'

import ModuleMappingSelect from './ModuleMappingSelect'
import ModuleAdvancedOptions from './ModuleAdvancedOptions'
const AssetItemThumb = () => import('@components/assets/asset-item-thumb')

export default {
  name: 'AssetBlock',

  components: {
    AssetItemThumb,
    ModuleMappingSelect,
    ModuleAdvancedOptions
  },

  mixins: [mixin],

  computed: {
    hasMapping() {
      if (this.mod && this.mod.target) {
        return this.mod.target.length > 0
      }
      return false
    },

    canSelectMapping() {
      return !this.isScene
    },

    canSelectEnabled() {
      return !this.isScene
    },

    canRemove() {
      return !this.isScene
    },

    isScene() {
      return this.type === 'scene'
    },

    asset() {
      if (this.resourceId) {
        return this.$store.getters['assets/getAssetById'](this.resourceId)
      }
      return false
    },

    resourceId() {
      if (this.isScene) {
        if (this.mod.id && this.mod.id !== null) return this.mod.id
      } else if (this.mod.resource && this.mod.resource !== null)
        return this.mod.resource.id

      return null
    },

    hasAsset() {
      return this.resourceId !== null
    }
  },

  watch: {
    resourceId: {
      handler: 'fetchAssetInfo',
      immediate: true
    }
  },

  methods: {
    onSelect() {
      this.$bus.$emit('overlay:drivemodal', {
        size: 'sm',
        props: {
          type: this.type,
          mod: this.mod,
          timeline: this.timeline
        }
      })
    },

    fetchAssetInfo() {
      if (this.resourceId) {
        this.$store.dispatch('assets/loadAsset', { id: this.resourceId })
      }
    }
  }
}
</script>
