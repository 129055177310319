<template>
  <ViewerBlock
    :asset="asset"
    :viewmode="viewmode"
  />
</template>
<script>
const ViewerBlock = () => import('@components/viewer/ViewerBlock')

export default {
  name: 'ViewerContainer',

  components: {
    ViewerBlock
  },

  props: [
    'asset', 'viewmode'
  ]
}
</script>
