
const CollapseGroup = () => import('@components/editor/utils/CollapseGroup')
const CollapseRow = () => import('@components/editor/utils/CollapseRow')
const BlockGroup = () => import('@components/editor/utils/BlockGroup')

export default {
  components: {
    CollapseGroup, BlockGroup, CollapseRow
  }
}
