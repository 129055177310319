import InputWrapper from '@components/editor/module/inputs/InputWrapper'
import BlockHeader from '@components/editor/scene/blocks/BlockHeader'
import BlockGroup from '@components/editor/scene/blocks/BlockGroup'

export default {

  components: {
    InputWrapper,
    BlockHeader,
    BlockGroup
  },

  props: {
    mod: {
      required: true
    },
    timeline: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'module'
    }
  },

  methods: {
    getSetting (key) {
      return this.mod.getSetting(key)
    },

    setSetting (key, value) {
      this.timeline.editModuleSetting(this.mod, key, value)
    }
  }
}
