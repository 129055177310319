<template>
  <div>
    <InputWrapper
      type="toggle"
      :label="'Flip Y'"
      :extra="'Flip the texture vertically'"
      :value="flipYSetting"
      @change="setSetting('flipY', $event)"
    />

    <!-- <InputWrapper
        type="toggle"
        v-bind:label="'Chroma Key Texture'"
        v-bind:extra="'Enable Chromakey on texture. Use for transparency via greenscreen backgrounds'"
        v-bind:value="mod.getSetting('chromaKey')"
        v-on:change="setSetting('chromaKey', $event)"/> -->

    <InputWrapper
      type="select"
      :label="'Output'"
      :extra="'How this texture applies on the models'"
      :value="textureChannelValueSafe"
      :values="textureChannelOptions"
      @change="setSetting('textureChannel', $event)"
    />

    <!-- <BlockGroup v-bind:label="'Texture Options'" v-bind:closed="false"> -->
    <CollapseGroup
      :label="'Texture Options (1)'"
      :level="1"
    >
      <div class="flex-1 ml-2 bg-gray-800 border-t border-gray-700">
        <div class="">
          <!--
            <CollapseGroup v-bind:label="'Chroma Key'"v-bind:level="1">
              <template v-slot:header>
                <div class="flex items-center justify-center ml-1 grow-0">
                  <input v-model="chromaEnabled" type="checkbox" class="w-3 h-3 mx-1 text-indigo-600 rounded-sm form-checkbox">
                  <input v-model="chromaColor" type="color" :disabled="!chromaEnabled" class="flex-auto w-16 h-5 bg-gray-700 border border-gray-800 rounded focus:bg-gray-600 focus:border-gray-500">
                </div>
              </template>
            </CollapseGroup> -->

          <CollapseGroup
            :label="'Luma Matte'"
            :level="1"
          >
            <template v-slot:header>
              <input
                v-model="lumaMatteEnabled"
                type="checkbox"
                class="w-3 h-3 mx-1 text-indigo-600 rounded-sm form-checkbox"
              >
            </template>

            <!-- <div class="flex-1 bg-gray-800 border-t border-gray-700">
                <div class="px-4 py-2 text-xs italic text-gray-400">
                  Requires content to be formatted in a specific way.<br/> See <a href="#" target="_blank" class="underline cursor-pointer hover:text-white">Using Luma Matte documenation</a> for details
                </div>
              </div> -->
          </CollapseGroup>
        </div>
      </div>
    </CollapseGroup>
  </div>
</template>
<script>
import mixin from './ModuleMixin'
import utilsmixin from '@components/editor/utils/utilsmixin.js'

export default {

  mixins: [mixin, utilsmixin],

  props: ['mod', 'timeline'],

  computed: {

    lumaMatteEnabled: {
      get () {
        return this.mod.getSetting('textureAsLumaMatte')
      },
      set (val) {
        this.setSetting('textureAsLumaMatte', val)
      }
    },

    chromaEnabled: {
      get () {
        return this.mod.getSetting('chromaKeyEnabled')
      },
      set (val) {
        this.setSetting('chromaKeyEnabled', val)
      }
    },

    chromaColor: {
      get () {
        let c = this.mod.getSetting('chromaKeyColor')
        if (c === null) return '#17fe1a'
        return c
      },
      set (val) {
        this.setSetting('chromaKeyColor', val)
      }
    },

    flipYSetting () {
      let val = this.mod.getSetting('flipY')

      if (val === undefined) val = true

      return val
    },

    textureChannelValue () {
      return this.mod.getSetting('textureChannel')
    },

    textureChannelValueSafe () {
      if (this.textureChannelValue === null) return 'emissiveandbase'
      return this.textureChannelValue
    },

    textureChannelOptions () {
      return {
        'emissiveandbase': 'Emissive & Base (default)',
        'emissive': 'Emissive',
        'default': 'Base',
        'alpha': 'Alpha',
        'none': 'None'
      }
    }
  },

  methods: {
    setSetting (key, value) {
      this.timeline.editModuleSetting(this.mod, key, value)
    }
  }
}

</script>
