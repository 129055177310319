<template>
  <div class="flex flex-wrap bg-gray-800">
    <div class="w-full">
      <BlockGroup :label="'Settings'" :closed="false">

        <InputWrapper
          type="text"
          :label="'Module Name'"
          :value="moduleName"
          @change="moduleName = $event"
        />
        <div class="text-xs bg-gray-800 tabs sub-tabs">
          <template v-for="tab in tabs">
            <a
              href="#"
              class="border-b-2 tab"
              :class="{'font-bold underline rounded-t-sm': active === tab.type}"
              @click.prevent="active = tab.type"
            >
              <span class="text-gray-100">{{ tab.label }}</span>
            </a>
          </template> 
        </div>

        <template v-if="active === 'frames'">
          <InputWrapper
            key="starts_at_frames"
            type="number"
            :label="'Starts at'"
            :units="'f'"
            :value="startAtFrame"
            @change="startAtFrame = $event"
          />
          <InputWrapper 
            key="duration_frames"
            type="number"
            :label="'Duration'"
            :units="'f'"
            :value="durationFrame"
            @change="durationFrame = $event"
          />
          <InputWrapper 
            key="ends_at_frames"
            type="number"
            :label="'Ends At'"
            :units="'f'"
            :value="endAtFrame"
            @change="endAtFrame = $event"
          />
        </template>
        <template v-else>
          <InputWrapper
            key="starts_at_secs"
            type="number"
            :label="'Starts at'"
            :units="'s'"
            :value="startAt"
            @change="startAt = $event"
          />

          <InputWrapper
            key="duration_secs"
            type="number"
            :label="'Duration'"
            :units="'s'"
            :value="duration"
            @change="duration = $event"
          />

          <InputWrapper
            key="ends_at_secs"
            type="number"
            :label="'Ends at'"
            :units="'s'"
            :value="endAt"
            @change="endAt = $event"
          />
        </template>

        <InputWrapper
          type="button"
          :simple="true"
          :value="'Delete'"
          @change="onDelete"
        />
      </BlockGroup>
    </div>
  </div>
</template>
<script>
import mixin from './ModuleMixin'

const ModuleDeleteModal = () => import('@modals/ModuleDelete')

export default {
  name: 'MappingBlock',

  mixins: [mixin],

  data () {
    return {
      active: 'frames',
      tabs: [
        { type: 'frames', label: 'Frames' },
        // { type: 'render', label: 'Render' },
        { type: 'seconds', label: 'Seconds' }
      ],
      active: 'frames'
    }
  },

  computed: {
    moduleName: {
      get() {
        return this.mod.name
      },
      set(val) {
        this.mod.name = val
        this.timeline.editModuleName(this.mod, val)
      }
    },

    startAt: {
      get() {
        return this.mod.start_at
      },
      set(val) {
        this.mod.start_at = val
        this.timeline.editModuleStartTime(this.mod, val)
      }
    },

    startAtFrame: {
      get() {
        return this.mod.start_at_frame
      },
      set(val) {
        this.mod.start_at_frame = val
        this.timeline.editModuleStartTimeFrame(this.mod, val)
      }
    },


    durationFrame: {
      get() {
        return this.mod.duration_frame
      },
      set(val) {
        this.timeline.editModuleDurationFrame(this.mod, val)
        return true
      }
    },
    
    endAtFrame: {
      get() {
        return this.mod.end_at_frame
      },
      set(val) {
        this.mod.end_at_frame = val
        this.timeline.editModuleEndTimeFrame(this.mod, val)
      }
    },

    endAt: {
      get() {
        return this.mod.end_at
      },
      set(val) {
        this.timeline.editModuleEndTime(this.mod, val)
        return true
      }
    },

    duration: {
      get() {
        return this.mod.duration
      },
      set(val) {
        this.timeline.editModuleDuration(this.mod, val)
        return true
      }
    }
  },

  methods: {
    onDelete() {
      this.$modal.show(
        ModuleDeleteModal,
        {
          mod: this.mod,
          successCallback: () => {
            this.$emit('close')
          }
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    }
  }
}
</script>
